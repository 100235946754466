$opacities: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;
$dark-blue: #060B24;

@each $opacity in $opacities {
  .o-#{$opacity} {
    opacity: calc($opacity / 100);
  }
}

.bg-cyan {
  background-color: $cyan;
}