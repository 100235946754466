@font-face {
  font-family: 'Nico Moji';
  src: url('../fonts/Nico-Moji-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.app {
  background-color: $dark-blue;
  min-height: 100vh;
  color: $white;
  @media (max-width : 576px) {
    background: url("../images/bg-mb.png");
    background-repeat: no-repeat;
    background-size: cover;
    color:#272727;
    padding: 0;
  }
}

.login-screen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .col-gif{
    @media (max-width: 576px) {
        display: none;
    }
  }
}

.welcome-text {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 1px 10px rgba(0, 240, 255, 0.60);
  font-family: Nico Moji;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -3.25px;

}
.welcome-title {
  font-family: 'Nico Moji';
  font-size: 60px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #3060CF;
  text-wrap: nowrap;
}
.create-account-text {
  color: #FFF;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login-form-container {
  width: 500px;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.20);
  @media (max-width: 576px) {
    // width :auto;
    // padding: 20px !important;
  }
  .input-group{
    svg{
      color: #ccc;
    }
    .form-control{
      border: none;
     
    }
    .input-group-text{
      border-bottom-left-radius: 20px !important;
      border-top-left-radius: 20px !important;
    }
    input{
      outline: none;
      border-bottom-right-radius: 20px !important;
      border-top-right-radius: 20px !important;
    }:focus{
      box-shadow:none;
      border: 1px solid #D5D4DC;
    }::placeholder {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: rgba(73, 73, 73, 0.6);      
    }
  }
  .bg-cyan{
    font-weight: bold;
    border-radius:20px ;
  }
}

.stars {
  background: url('../images/overlay_stars.svg');
  min-height: 100vh;
}

.central-body {
  text-align: center;
  padding: 17% 5% 10% 5%;
}

.image-404 {
  position: relative;
  pointer-events: none;
  width: 404px;
}

.objects img {
  pointer-events: none;
}

.object_rocket {
  width: 40px;
  position: absolute;
  transform: translateX(-50px);
  top: 75%;
  animation: rocket-movement 200s linear infinite both running;
  animation-duration: 200s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: rocket-movement;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}

.box_astronaut {
  width: 140px;
  position: absolute;
  top: 60%;
  right: 20%;
  will-change: transform;
  animation: move-astronaut 50s infinite linear both alternate;
  animation-duration: 50s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: move-astronaut;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}

.object_astronaut {
  animation: rotate-astronaut 200s infinite linear both alternate;
  animation-duration: 200s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: rotate-astronaut;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}

.object_earth {
  width: 100px;
  position: absolute;
  top: 20%;
  left: 15%;

}

.object_moon {
  width: 80px;
  position: absolute;
  top: 12%;
  left: 25%;
}

.glowing_stars .star:nth-child(1) {
  top: 80%;
  left: 25%;
  animation: glow-star 2s infinite ease-in-out alternate 1s;
}

.glowing_stars .star {
  position: absolute;
  border-radius: 100%;
  background-color: #fff;
  width: 3px;
  height: 3px;
  opacity: 0.3;
  will-change: opacity;
}

.btn-go-home {
  position: relative;
  margin: 15px auto;
  width: 150px;
  padding: 10px 15px;
  border: 1px solid #FFCB39;
  border-radius: 100px;
  font-weight: 400;
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 11px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.social{
  width: 500px;
  .facebook{
    align-items: center;
    background: #407cd7;
    border-radius: 9px;
   
  }
  .google{
    align-items: center;
    background: #d81c1c;
    border-radius: 9px;
    @media (max-width: 576px) {
      font-size: 10px;
    }
  }
}
@keyframes rocket-movement {
  100% {
    -webkit-transform: translate(1200px,-600px);
    transform: translate(1200px,-600px);
}
}

@keyframes move-astronaut {
  100% {
    -webkit-transform: translate(-160px, -160px);
    transform: translate(-160px, -160px);
  }
}

@keyframes rotate-astronaut {
  100% {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}



.register-mb{
  @media (max-width : 576px) {
    padding: 0;
    .res-header{
      background-color: #f5f8ff;
      border-bottom: 1px solid #00000026;
      height: auto;
      min-height: 8vh;
      padding: 15px;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 9999;
      font-size: 19px;
      font-weight: 600;
      line-height: 35px;
      text-align: center;
      text-transform: capitalize;
    }
    .res-img{    
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      .hava-a-nice-day{
        width: 231px;
      }
      .cute-gift{
        width: 231px;
      }
    }
    .res-form{
      padding: 0 20px;
      // width :100%;
      .login-form-container{
        width :100%;
        .input-group input{
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          padding: 10px;
          border: 1px solid #ccc;
          border-left: none;
        }
        .input-group-text{
          border-bottom-left-radius: 5px !important;
          border-top-left-radius: 5px !important;
          background-color: #FFF;
        }
        .bg-cyan{
          background-color: #3060cf;
          border-radius: 30px;
          color: #fff;
          padding: 10px;
          width: 100%;
        }
      }
    } 
    .process-bar{
      .process-bar-item{
        flex: 1;
        height: 1px;
        .progress-bar{
          background: #272727;
        }
      }
      .process-bar-text{
        flex: 2;
        text-align: center;
      }
    }
    .social{
      width: auto;
      .facebook,.google{
        font-size: 15px;
      }
    }
  }
}